import Cookies from 'js-cookie'
const SECRET_PASSWORD = 'PRUMDRT2022'
const defaultOpts = {
  expires: 365,
  path: ''
}
const userAuth = {
  getAuth: () => {
    let authToken = Cookies.get('auth-token')
    return {
      isAuthenticated: !!authToken,
      authToken
    }
  },

  setAuth: (args) => {
    Cookies.set('auth-token', args['auth_token'], defaultOpts)
  },

  clearAuth: () => {
    Cookies.remove('auth-token', defaultOpts)
  },

  checkLogin: (password) => {
    return password === SECRET_PASSWORD
  },

  generateToken: (_args) => {
    return 'random-token'
  }
}

export default userAuth
