import React, { useState, useEffect } from 'react'
import { classNames, classNameObject } from 'utils'
import tick from '../../images/ondemand/tick@2x.png'
import unTick from '../../images/ondemand/untick@2x.png'

function Checkbox({
  id,
  name,
  defaultValue,
  onChange,
}) {

  const [checked, setChecked] = useState(false)


  useEffect(() => {
    if (defaultValue !== checked) {
      setChecked(defaultValue)
    }
  }, [defaultValue])

  function handleChange(event) {
    const value = event.target.checked
    setChecked(value)
    if (typeof onChange === 'function') {
      onChange(value)
    }
  }

  return (
    <>
      <input
        id={id}
        type="checkbox"
        style={{ display: 'none' }}
        name={name}
        onChange={handleChange}
        checked={checked}
      />
      <label htmlFor={id} className="cursor-pointer flex w-5 h-5 md:w-7 md:h-7 mt-1 md:mt-0 justify-center items-end">
        <img className="w-5 md:w-6" src={checked ? tick : unTick} alt="" />
      </label>
    </>
  )
}

export default React.memo(Checkbox)
