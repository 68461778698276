import React from 'react'
import './style.css'
import { classNames } from 'utils'
export default function Loading({ loading }) {
  return (
    <div
      className={classNames({
        'w-full flex justify-center items-center': true,
        hidden: !loading
      })}
    >
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
