import { isEmptyObject } from './validate'

export function classNames(classNameHash) {
  return Object.entries(classNameHash)
    .map((pair) => (pair[1] ? pair[0] : null))
    .filter((x) => x)
    .join(' ')
}

export function classNameObject(classNameString) {
  if (!classNameString) return {}
  return classNameString.split(' ').reduce(
    (prev, className) => ({
      ...prev,
      [className]: true
    }),
    {}
  )
}

export function formatErrors(errors) {
  if (!isEmptyObject(errors)) {
    let dupErrors = { ...errors }
    Object.keys(dupErrors).forEach((key) => {
      const value = dupErrors[key]
      dupErrors[key] = value.join(', ')
    })
    return dupErrors
  } else {
    return {}
  }
}
