export default function getConfig() {
  const env = process.env.REACT_APP_HOST_TYPE || 'development'
  const configs = {
    development: {
      baseApiUrl: 'http://localhost:3000/api'
    },
    staging: {
      baseApiUrl: 'https://prudential-mdrt-2022.projectwebby.com/api'
    },
    production: {
      baseApiUrl: 'https://www.registration.pruevent-asia.com/api'
    }
  }

  return configs[env]
}
