import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import mainLogo from '../../images/prudential-logo.png'
import contact from '../../images/contact.svg'
import vietnam from '../../images/vietnam.svg'
import indonesia from '../../images/indonesia.svg'
import usa from '../../images/usa.svg'
import china from '../../images/china.svg'
import Select from 'react-select'
import HamburgerMenu from 'react-hamburger-menu'
import { classNames } from '../../utils'
import { useLocation } from 'react-router-dom'
import parse from 'html-react-parser'
import OnDemandBanner from '../OnDemandBanner'

function formatOptionLabel({ icon, language }) {
  return (
    <span className="flex item-center border-none text-sm">
      <img className="w-5 h-5 mx-2" src={icon} />
      <p>{language}</p>
    </span>
  )
}

function Header({ locale }) {
  const { t } = useTranslation()
  const [state, setState] = useState({ open: false })
  const [language, setLanguages] = useState(null)
  const options = [
    { value: 'en', language: 'English', icon: usa },
    { value: 'id', language: 'Bahasa Indonesia', icon: indonesia },
    { value: 'cn', language: '中文简体', icon: china },
    { value: 'vi', language: 'Tiếng Việt', icon: vietnam }
  ]

  useEffect(() => {
    const option = options.find((option) => {
      return option.value === locale
    })
    setLanguages(option)
  }, [])

  const customStyles = {
    control: () => ({
      boxShadow: '0 0px 8px 2px rgba(0, 0, 0, 0.2)',
      border: 'none',
      borderRadius: '5px',
      height: 30,
      minHeight: 30,
      display: 'flex',
      justifyContent: 'center',
      justifyItems: 'center'
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: 0
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none'
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : '#68737A',
      cursor: 'pointer'
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 })
  }

  function handleClick() {
    setState({ open: !state.open })
  }
  let location = useLocation()

  function handleChangeLanguage(option) {
    let newPath = location.pathname
    if (locale) {
      newPath = newPath.replace(`/${locale}`, '')
    }
    newPath = `/${option.value}${newPath}`
    window.location.href = newPath
  }

  const homeRoute = locale ? `/${locale}` : '/'

  const locales = {
    en: 'en',
    cn: 'sc',
    vi: 'vn',
    id: 'id'
  }

  const pruEventLocale = locales[locale]

  const soYouLink =
    process.env.REACT_APP_HOST_TYPE === 'production'
      ? `https://www.soyouthinkyoucandance.pruevent-asia.com/${locale}`
      : `https://soyouthinkyoucandance.projectwebby.com/${locale}`

  return (
    <div className="relative z-20 w-full flex justify-center">
      <OnDemandBanner locale={locale} />
      <div className="absolute left-0 top-0 w-full flex justify-center">
        <div className="relative px-0 md:px-6 max-w-screen-xl w-full">
          <div className="w-full md:shadow-header">
            <div className="relative flex">
              <span className="flex justify-start md:justify-center items-center bt-0 md:mt-4 md:mt-0 md:mb-0 mb-10 md:absolute md:block -left-21 top-0 md:z-10 w-full md:w-auto bg-white md:bg-transparent h-16 md:h-auto">
                <img
                  className="absolute xs:top-0 xs:left-0 md:relative xs:w-28 sm:w-38 h-auto w-21 top-1 left-1"
                  src={mainLogo}
                />
              </span>
              <HamburgerMenu
                isOpen={state.open}
                menuClicked={() => handleClick()}
                width={31}
                height={25}
                strokeWidth={4}
                rotate={0}
                color="rgb(239, 26, 47)"
                borderRadius={10}
                animationDuration={0.2}
                className="absolute right-5 top-5 md:hidden"
              />
              <div
                className={classNames({
                  'md:flex pt-2 md:pb-3.5 pb-2 pl-3 md:pr-6 md:flex-col w-full justify-center items-center border border-gray-border shadow-lg md:shadow-none md:border-none md:bg-none top-16 md:top-0  bg-white px-3 flex-col-reverse z-10 md:z-0': true,
                  'absolute md:relative ': state.open,
                  'hidden ': !state.open
                })}
              >
                <div className="relative flex flex-row md:justify-end w-full pl-2 md:pl-0 md:pr-5 md:mt-1 md:mb-8 text-gray my-6 justify-between">
                  <Select
                    menuPortalTarget={document.body}
                    value={language}
                    formatOptionLabel={formatOptionLabel}
                    styles={customStyles}
                    options={options}
                    className="w-56 mr-2 cursor-pointer"
                    isSearchable={false}
                    onChange={(option) => handleChangeLanguage(option)}
                  />
                  {/* <a
                    className="flex text-sm currsor-pointer"
                    href="mailto:prumdrt2022@mci-group.com"
                  >
                    <span className="underline pr-3 md:px-2">
                      {t('privacy_notice_footer.contact_us')}
                    </span>
                    <img className="w-5 h-5" src={contact} />
                  </a> */}
                </div>
                <div className="w-full flex md:flex-row justify-center md:items-center font-bold text-sm md:pt-4 px-0 flex-col items-start text-gray">
                  <a
                    className="cursor-pointer text-center px-2 hover:text-primary lg:px-4 py-2 uppercase"
                    href={`https://prumdrt2022.pruevent-asia.com/${pruEventLocale}`}
                    rel="noreferrer"
                  >
                    {parse(t('privacy_notice_footer.home'))}
                  </a>
                  <a
                    className="cursor-pointer text-center px-2 hover:text-primary lg:px-4 py-2 uppercase"
                    href={`https://prumdrt2022.pruevent-asia.com/${pruEventLocale}/agenda`}
                    rel="noreferrer"
                  >
                    {parse(t('privacy_notice_footer.agenda'))}
                  </a>
                  <a
                    className="cursor-pointer text-center px-2 hover:text-primary lg:px-4 py-2 uppercase"
                    href={`https://prumdrt2022.pruevent-asia.com/${pruEventLocale}/speakers`}
                    rel="noreferrer"
                  >
                    {parse(t('privacy_notice_footer.speakers'))}
                  </a>
                  <a
                    className="cursor-pointer text-center px-2 hover:text-primary lg:px-4 py-2"
                    href={`https://prumdrt2022.pruevent-asia.com/${pruEventLocale}/wedo`}
                    rel="noreferrer"
                  >
                    {parse(t('privacy_notice_footer.we_do_activities'))}
                  </a>
                  <a
                    className="cursor-pointer text-center px-2 hover:text-primary lg:px-4 py-2"
                    href={`https://prumdrt2022.pruevent-asia.com/${pruEventLocale}/runtomdrt`}
                    rel="noreferrer"
                  >
                    {parse(t('privacy_notice_footer.run_to_mdrt'))}
                  </a>
                  <a
                    className="cursor-pointer uppercase text-center  w-auto md:w-38 lg:w-36 align-center px-2 lg:px-4 py-2 hover:text-primary"
                    href={`https://prumdrt2022.pruevent-asia.com/${pruEventLocale}/halloffame`}
                    rel="noreferrer"
                  >
                    {parse(t('main_page.hall_of_fame'))}
                  </a>
                  <a
                    className="cursor-pointer w-40 text-center px-2 lg:px-4 pt-2 pb-1 rounded-full text-white bg-primary ml-2 sm:ml-0 uppercase"
                    href={homeRoute}
                  >
                    {parse(t('main_page.on_demand'))}
                  </a>
                  <a
                    className="cursor-pointer text-center px-3 hover:text-primary lg:px-5 py-2 uppercase"
                    href={`https://prumdrt2022.pruevent-asia.com/${pruEventLocale}/faq`}
                    rel="noreferrer"
                  >
                    {parse(t('privacy_notice_footer.faq'))}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
