import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import HomePage from './pages/home'
import Login from './pages/login'
import DefaultLayout from './layouts/DefaultLayout'
import AuthLayout from './layouts/AuthLayout'

import './App.css'

function App() {
  return (
    <Router>
      <Switch>
        <AuthLayout exact path="/" component={HomePage} />
        <DefaultLayout exact path="/login" component={Login} />
        {/* Locales routes */}
        <AuthLayout exact path="/:locale" component={HomePage} />
        <DefaultLayout exact path="/:locale/login" component={Login} />
      </Switch>
    </Router>
  )
}

export default App
