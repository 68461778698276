import React from 'react'
import bannerEn from '../../images/ondemand/banner_en.jpg'
import bannerCn from '../../images/ondemand/banner_cn.jpg'
import bannerId from '../../images/ondemand/banner_id.jpg'
import bannerVi from '../../images/ondemand/banner_vi.jpg'

function OnDemandBanner({ locale }) {
  function getBannerUrl() {
    switch (locale) {
      case 'en':
        return bannerEn
      case 'cn':
        return bannerCn
      case 'id':
        return bannerId
      case 'vi':
        return bannerVi

      default:
        return bannerEn
    }
  }

  return (
    <div className="relative w-full flex items-center justify-center overflow-hidden xs:mt-0 mt-8">
      <img className="object-cover w-full" src={getBannerUrl()} alt={''} />
    </div>
  )
}

export default React.memo(OnDemandBanner)
