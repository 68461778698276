import React, { useState, useReducer, useEffect } from 'react'
import HomeIndex from '../components/Home'

function Home({ t, i18n }) {

  return (
    <>
      <div className="h-full flex flex-col items-center py-10">
        <HomeIndex t={t} i18n={i18n} />
      </div>
    </>
  )
}

export default Home
