import React from 'react'
import OnDemand from './OnDemand'
const OnDemandList = ({ onDemands, t, i18n, onShowVideo }) => {
  return (
    onDemands.map((item, index) => {
      return <OnDemand
        onShowVideo={onShowVideo}
        t={t}
        key={index}
        i18n={i18n}
        video={item}
      />
    })
  )
}

export default OnDemandList
